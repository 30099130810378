import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { Link } from "@reach/router"

class JobCard extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    jobCategory: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
  }

  render() {
    const { jobCategory, title, details } = this.props

    return (
      <div className="jobs-item">
        <div className="jobs-card">
          <div className="jobs-card-details">
            <div className="jobs-card-details-title">{title}</div>
            <ul className="jobs-card-details-meta">
              <li>
                <div className="department">{jobCategory}</div>
              </li>
              <li>
                <div className="location">{details}</div>
              </li>
            </ul>
          </div>
          <div className="jobs-card-action">
            <Link
              to={slugify(title.toLowerCase())}
              className="btn btn-sm btn-primary"
            >
              See job details
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default JobCard
