import { graphql, useStaticQuery } from "gatsby"
import JobCard from "../JobCard"
import React from "react"

const JobsList = () => {
  const jobCategoriesData = useStaticQuery(graphql`
    query AllStrapiJobCategories {
      allStrapiJobCategories(sort: { fields: created_at, order: DESC }) {
        edges {
          node {
            name
            jobs {
              FullTime
              Remote
              Title
            }
          }
        }
      }
    }
  `)

  return (
    <div className="jobs">
      {jobCategoriesData["allStrapiJobCategories"]["edges"].map(
        ({ node }, i) => {
          return (
            <>
              <div className="jobs-category">{node.name}</div>
              {node.jobs.map((job, i) => {
                return (
                  <JobCard
                    title={job["Title"] || "NA"}
                    jobCategory={node.name || "NA"}
                    details={`${job["FullTime"] ? "Full-time Job" : ""}${
                      job["FullTime"] && job["Remote"] ? "," : ""
                    }
                    ${job["Remote"] ? "Remote" : ""}`}
                  />
                )
              })}
            </>
          )
        }
      )}
    </div>
  )
}

export default JobsList
