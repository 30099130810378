import React from "react"
import Menu from "../components/global/Menu"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/global/Footer"
import ReactMarkdown from "react-markdown"
import JobsList from "../components/global/JobsList"
import { graphql } from "gatsby"

const CareersPage = ({ data: { strapiJobsPage } }) => {
  return (
    <Layout bodyTheme="theme-about">
      <SEO
        title="Razroo Careers"
        keywords={[`about`, `razroo`, `angular`, `culture`]}
      />
      <header>
        <Menu />
      </header>
      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="consulting-grid align-items-center">
                  <div className="consulting-grid-thumb">
                    <img
                      alt=""
                      src={
                        strapiJobsPage["HeroImage"]["localFile"]["publicURL"]
                      }
                    />
                  </div>
                  <div className="consulting-grid-content">
                    <h1>{strapiJobsPage["Title"] || "N/A"}</h1>
                    <ReactMarkdown source={strapiJobsPage["About"] || "N/A"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 mt-lg-4">
              <div className="col-lg-12">
                <JobsList />
                <div className="jobs-about">
                  <h3>{strapiJobsPage["UniquelyOffers"]["Title"]}</h3>
                  <ReactMarkdown
                    source={
                      strapiJobsPage["UniquelyOffers"]["Content"] || "N/A"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query StrapiPageQuery {
    strapiJobsPage {
      Title
      About
      HeroImage {
        localFile {
          publicURL
        }
      }
      UniquelyOffers {
        Content
        Title
      }
    }
  }
`

export default CareersPage
